import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 361 359"
    >
      <path
        fill="#00d3a9"
        d="M63.5 113.5a133.13 133.13 0 0194-67.5l-8-46A181.53 181.53 0 0022 91z"
      ></path>
      <path
        fill="#00b39f"
        d="M65.5 247a133.42 133.42 0 01-19-69 130.14 130.14 0 018.5-46.5l-44-16A184.54 184.54 0 000 178a182.54 182.54 0 0025.5 93z"
      ></path>
      <path
        fill="#00d3a9"
        d="M182 312h-1.5C139 312 102 293 77 263l-36 30a181.18 181.18 0 00139.5 66h2z"
      ></path>
      <path
        fill="#00b39f"
        d="M297 244a134.07 134.07 0 01-95 66l7.5 46.5a180.18 180.18 0 00128-89.5z"
      ></path>
      <path
        fill="#00d3a9"
        d="M296 110.5a133.46 133.46 0 0118 67.5 128 128 0 01-9 48l44 17a184.66 184.66 0 0012-64.5 180.8 180.8 0 00-24.5-91z"
      ></path>
      <path fill="none" d="M251.3 17.85H295.3V85.65H251.3z"></path>
      <path
        fill="#20bdab"
        d="M261.4 85.65L251.3 75.55 275.1 51.75 251.3 27.95 261.4 17.85 295.3 51.75 261.4 85.65z"
      ></path>
      <path fill="none" d="M183.5 17.85H227.5V85.65H183.5z"></path>
      <path
        fill="#20bdab"
        d="M193.6 85.65L183.5 75.55 207.3 51.75 183.5 27.95 193.6 17.85 227.5 51.75 193.6 85.65z"
      ></path>
    </svg>
  );
}

export default Icon;
